

.service-expire-date{
    margin-bottom: 20px;

    .calendar-date{
        // color: #007a52;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 80px;

        h3{
            margin-bottom: 0 !important;
        }
        .calendar-date-day{
            width: 50px;
            text-align: center;
            display: flex;
            flex-direction: column;
            position: relative;

            .line-container{
                position: absolute;
                right: -35px;
                width: 35px;
                top: 0;
                height: 100%;
                margin: 0 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                .line{
                    height: 100%;
                    width: 2px;
                    // background-color: #007a52;
    
                }
                .circle{
                    width: .625rem;
                    height: .625rem;
                    position: absolute;
                    background-color: inherit;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin: auto;
                    border-radius: 5px;
                }
            }
        }

        .calendar-date-description{
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .17);
            // margin: 0 0 1.33rem .72rem;
            height: 100%;
            flex-grow: 1;
            margin-left: 35px;
            .calendar-date-description-content{
                height: 100%;
                display: flex;
                align-items: center;
                padding: 0 .89rem;
            }
        }
    }
}