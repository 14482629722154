
  
.up-lbl {
    transform: translateY(-30px);
}

.modal {
    background: rgba(0, 0, 0, .8);
    
}
.disabled-btn-pvt {
    pointer-events: none;
    color: grey !important;
    background-color: grey;
}

.disabled {
    pointer-events: none !important;
    color: grey !important;
}

.hidden {
    display: none;
}

.benBtn {
    outline: none;
    border: 1px solid var(--primary);
    background-color: transparent;
    color: var(--primary);
    border-radius: .5rem;
    align-items: center;
    font-size: 16px;
    margin-top: .6rem !important;
    margin-right: .2rem;
}

.chosen {
    outline: none;
    border: none;
    border-radius: .5rem;
    background: var(--primary);
    color: white;
    margin-right: .2rem;
    font-size: 16px;
    align-items: center;
}

.benBtn:hover {
    opacity: .9;
}

.benBtn:focus {
    opacity: .9;
}

.full-btn-file {
    width: 100%;
    padding: 2px;
}

.long {
    height: 200px !important;
}

p.change-file-span:hover {
    color: var(--primary);
}

.btn-left {
    /* margin-right: 150px; */
}

.center-forcer {
    margin-left: 28% !important;
}

.btn-color {
    background: var(--primary) !important; 
}

.save-spacer {
    /* margin-left: 310px; */
    /* display: flex;
    flex-direction: row;
    background-color: green; */
}

.tri {
   transform: translate(120px);
}

.btn-lyster {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 425px;
    transform: translateX(-20px);
}

.ttt {
    width: 800px;
    margin-left: 430px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.btn-spacer {
    display: flex;
    flex-direction: row;
    /* width: inherit; */
    /* justify-content: space-between; */
}


.btn-spacer-reduct{
    display: flex;
    flex-direction: row;
    /* width: inherit; */
    /* justify-content: space-between; */
    margin-left: -500px;
}


#step1Next {
    padding: 10px 25px 10px 50px;
    text-align: center;
    align-self: center;
    align-items: center;
    justify-content: center;
    transform: translateX(-400px);
}


.frw-btn {
    margin-top: -60px;
    width: 350px;
    transform: translateX(-500px);
}

.n-frw-btn {
    transform: translateX(250px) !important;

}

.bkw-btn {
    transform: translateX(-60px) !important;
}

.svd-btn {
    transform: translateX(-230px) !important;

}


.popping-out {
    transition: .2s linear all;
    opacity: 0;
    margin-top: -100px;
}

.popping-up {
    transition: .8s all linear;
    opacity: 1;
    /* margin-top: 100px; */
}

/* .first{
    border: solid 1px #565656;
    border-radius: 5px;
    display: flex;
    width: 600px;
    height: 90px;
    align-items: center;
    padding: 10px;
    margin-left: 400px !important;
} */

.err {
    margin-left: 150px !important;
}

.internal {
    width: 15px;
    height: 58px;
    background: #007a52;
    padding: 10px 0 10px 0;
    margin: -10px;
}

.internal-err {
    width: 15px;
    height: 58px;
    background: red;
    padding: 20px 0 68px 0;
    margin: -10px;
}

.succP {
    color: #565656;
    margin-top: 15px;
    margin-left: 30px;
    font-weight: 700;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.absolute-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000000000000;
    width: 500px;
    height: 520px !important;
}

::-webkit-scrollbar-corner { background: rgba(0,0,0,0.5); }

* {
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
    color: red;
    background-color: lightgrey;
}

*::-webkit-scrollbar-track {
    background: var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
    
    background-color: var(--scroll-bar-color);
    border-radius: 20px;
    border: 3px solid var(--scroll-bar-bg-color);
}


    .lds-dual-ring {
        display: inline-block;
        width: 80px;
        height: 80px;
        z-index: 10;
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%,-50%);
    }

    .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: grey transparent grey transparent;
    animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }


.mvr {
    margin-left: -290px !important;
}

.rqs-first {
    margin-left: 780px;
    margin-bottom: 20px;
}

.icon-size {
    width: 20px !important;
}

.btn-mover {
    transform: translateX(200px);
    font-size: 10px;
}

.date-mover {
    margin-top: 20px;
    margin-left: -100px;
    /*
    margin-bottom: 20px !important; */
}

.s-modal {
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: 10;
    background: rgba(0,0,0,0.7);
    left: 0;
    top: 0;
}

.hiding {
    opacity: .6;
    pointer-events: none;
}

.mediaq {
    width: 800px !important;
}

.ass-mover {
    margin-left: 180px;
}

.ass-mover-save {
    margin-left: -80px;
}

.cpa-mover {
    transform: translateX(-190px) !important;
}

.check-mover {
    transform: translateX(-400px) !important;

}

.box-remover {
    background-color: white !important;
    height: 120px;
}

.box-enlighter {
    transform: translateY(-50px);
    width: 105%;
    margin-left: -18px;
}

.top-move {
    margin-top: 20px !important;
}

/* /////////// */

.transparent {
    opacity: 0;
}

.first {
    border: solid 1px #565656;
    border-radius: 5px;
    display: flex;
    width: auto;
    align-items: center;
    padding: 10px;    
    background-color: white;
    justify-content: flex-start;
    transform: translateX(-10%);


}

.internal-btn {
    padding: .2rem;
    border-radius: .2rem;
    background: var(--primary); 
    color: white;
    float: right;
    transform:translate(1.4rem,4rem); 
    font-size: 13px
}

.internal-btn.disabled{
    opacity: 0.5;
    color: white !important;
}

.internal-err {
    width: 15px;
    height: auto;
    background: red;
    padding: 20px 0 68px 0;
    margin: -10px;
}

.dims {
    width: 480px !important;
    height: 530px !important;
}

.bkw-btn {
    transform: translateX(-30px) !important;
}

.svd-btn {
    transform: translateX(-170px) !important;
}

/* ///////////////TEST///////////////////// */

.bkw-btn-non-summ {
    transform: translateX(-100px) !important;
}

.svd-btn-non-summ {
    transform: translateX(-240px) !important;
}

.frw-btn-non-summ {
    transform: translateX(220px) !important;
}

.in-err {
    border-bottom: solid 1px red !important;
}


@media (max-width: 1400px) {
 
    .bkw-btn {
        transform: translateX(-50px) !important;
    }

    .svd-btn {
        transform: translateX(-190px) !important;
    }

    .ttt {
        width: 700px;
        margin-left: 370px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
    } 
    
    .btn-spacer {
        display: flex;
        flex-direction: row;
    }

    .btn-lyster {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 390px;
        transform: translateX(-20px);
    }

    .tri {
        transform: translate(40px);
     }

    .ending {
        margin-left: 530px !important;
    }

    .rqs-first {
        margin-left: 680px;
        margin-bottom: 20px;
    }

    .mvr {
        margin-left: -330px !important;
    }

    #step1Next {
        padding: 10px 25px 10px 50px;
        text-align: center;
        align-self: center;
        align-items: center;
        justify-content: center;
        transform: translateX(-340px);
    }

    .ass-mover {
        margin-left: 190px;
    }
    
    .ass-mover-save {
        margin-left: -40px;
    }

    .cpa-mover {
        transform: translateX(-150px) !important;
    }

    .check-mover {
        transform: translateX(-340px) !important;
    }

    .box-remover {
        background-color: white !important;
        height: 120px;
    }
    
    .box-enlighter {
        transform: translateY(-50px);
        width: 106%;
        margin-left: -18px;
    }

    .bkw-btn-non-summ {
        transform: translateX(-75px) !important;
    }
    
    .svd-btn-non-summ {
        transform: translateX(-220px) !important;
    }
    
    .frw-btn-non-summ {
        transform: translateX(200px) !important;
    }
}

@media (max-width: 1200px) {
 
    .bkw-btn {
        transform: translateX(-60px) !important;
    }

    .svd-btn {
        transform: translateX(-205px) !important;
    }

    .ttt {
        width: 600px;
        margin-left: 340px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
    } 
    
    .btn-spacer {
        display: flex;
        flex-direction: row;
    }

    .btn-lyster {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 330px;
        transform: translateX(-20px);
    }


    #step1Next {
        padding: 10px 25px 10px 50px;
        text-align: center;
        align-self: center;
        align-items: center;
        justify-content: center;
        transform: translateX(160px) !important;
    }


    /* .first{
        border: solid 1px #565656;
        border-radius: 5px;
        display: flex;
        width: 500px;
        height: 90px;
        align-items: center;
        padding: 10px;
        margin-left: 300px !important;
    } */


    .ending {
        margin-left: 640px !important;
    }

    .tri {
        transform: translate(-65px);
     }

    .rqs-first {
        margin-left: 560px;
        margin-bottom: 20px;
    }

    .mvr {
        margin-left: -270px !important;
    }

    
    .ass-mover {
        margin-left: 120px;
    }
    
    .ass-mover-save {
        margin-left: 0px;
    }

    .cpa-mover {
        margin-left: -330px;
    }

    .check-mover {
        margin-left: -480px;
    }

    .box-remover {
        background-color: white !important;
        height: 120px;
    }
    
    .box-enlighter {
        transform: translateY(-50px);
        width: 107%;
        margin-left: -18px;
    }

    .bkw-btn-non-summ {
        transform: translateX(-50px) !important;
    }
    
    .svd-btn-non-summ {
        transform: translateX(-210px) !important;
    }
    
    .frw-btn-non-summ {
        transform: translateX(300px) !important;
    }
}

@media (max-width: 991px) {
 
    .ttt {
        width: 300px;
        margin-left: 50px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
    } 
    
    
    .btn-spacer {
        display: flex;
        flex-direction: row;
    }

    .btn-lyster {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 350px;
        transform: translateX(-20px);
    }


    #step1Next {
        padding: 10px 25px 10px 50px;
        text-align: center;
        align-self: center;
        align-items: center;
        justify-content: center;
        transform: translateX(0px) !important;
    }

    /* .first{
        border: solid 1px #565656;
        border-radius: 5px;
        display: flex;
        width: 450px;
        height: 90px;
        align-items: center;
        padding: 10px;
        margin-left: 0px !important;
    } */

    .rqs-first {
        margin-left: 250px;
        margin-bottom: 20px;
    }

    .mvr {
        margin-left: 70px !important;
    }

    .cpa-mover {
        margin-left: -320px;
    }

    .check-mover {
        margin-left: -320px;
    }

    .box-remover {
        background-color: white !important;
        height: 120px;
    }
    
    .box-enlighter {
        transform: translateY(-50px);
        width: 109%;
        margin-left: -24px;
        margin-top: 35px;
    }

    .internal-btn {
        padding: .2rem;
        border-radius: .2rem;
        background: var(--primary); 
        color: white;
        float: right;
        transform:translate(1.4rem,3.5rem); 
        font-size: 13px
    }
    

}

@media (max-width: 767px) {
    .rqs-first {
        margin-left: 150px;
        margin-bottom: 20px;
    }

    .mvr {
        margin-left: 15px !important;
    }

    .ass-mover {
        margin-left: 0px;
    }
    
    .ass-mover-save {
        margin-left: 110px;
    }

    .cpa-mover {
        margin-left: -340px;
    }

    .check-mover {
        margin-left: -340px;
    }

    .box-remover {
        background-color: white !important;
        height: 120px;
    }
    
    .box-enlighter {
        transform: translateY(-50px);
        width: 112.2%;
        margin-left: -24px;
        box-shadow: none !important;
        margin-top: 35px;

    }

    .save-spacer {
        transform: translateX(-10px) scale(0.7) !important;
    }

    .btn-lyster {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: -60px;
        transform: translateX(30px) scale(0.8) !important;
    }

    .bkw-btn {
        transform: translateX(-70px) !important;
    }

    .mbl-btn {
        transform: translate(-180px,0px) scale(0.8) !important;
    }

    .tri {
        transform: translate(-90px,0px) scale(0.8) !important;
    }

    .svd-btn-non-summ {
        transform: translateX(-250px) !important;
    }

    .internal-btn {
        padding: .2rem;
        border-radius: .2rem;
        background: var(--primary); 
        color: white;
        float: right;
        transform:translate(1.4rem,3.5rem); 
        font-size: 13px
    }
}

@media (min-width: 450px) and (max-width: 522px){
    .tri {
        transform: translate(-130px,0px) scale(0.8) !important;
    } 


    .btn-lyster {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: -60px;
        transform: translateX(30px) scale(0.7) !important;
    }
    
    .bkw-btn {
        transform: translateX(-50px) !important;
    }

    
    .mbl-btn {
        transform: translate(-185px,0px) scale(0.7) !important;
    }

    .rqs-first {
        margin-left: 50px;
        margin-bottom: 20px;
        transform: translateX(60px);
    }

   
}

@media (min-width: 522px) and (max-width: 574px){

    .btn-lyster {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: -40px;
        transform: translateX(-90px) scale(0.7) !important;
    }


    .mbl-btn {
        transform: translate(-200px,0px) scale(0.8) !important;
    }

    .tri {
        transform: translate(-120px,0px) scale(0.8) !important;
    }

    .save-spacer {
        transform: translateX(-30px) scale(0.6) !important;
    }

    .btn-lyster {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: -60px;
        transform: translateX(30px) scale(0.7) !important;
    }
    
    .bkw-btn {
        transform: translateX(-70px) !important;
    }

    .rqs-first {
        transform: translateX(60px);
        margin-left: 100px;
        margin-bottom: 20px;
    }

    
    .cpa-mover {
        margin-left: -390px;
    }

    .check-mover {
        margin-left: -390px;
    }

    .internal-btn {
        padding: .2rem;
        border-radius: .2rem;
        background: var(--primary); 
        color: white;
        float: right;
        transform:translate(1.4rem,3.5rem); 
        font-size: 13px
    }

}

@media(max-width: 680px) and (min-width: 574px) {
    .ttt {
        width: 200px;
        margin-left: 50px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
    } 
    
    .btn-spacer {
        display: flex;
        flex-direction: row;
    }

    .btn-lyster {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: -60px;
        transform: translateX(-90px) scale(0.7) !important;
    }

    .save-spacer {
        transform: translateX(-115px) scale(0.5) !important;
    }

    #step1Next {
        padding: 10px 25px 10px 50px;
        text-align: center;
        align-self: center;
        align-items: center;
        justify-content: center;
        transform: translateX(0px) !important;
    }

    .check-mover {
        margin-left: -340px;
    }



    .mbl-btn {
        transform: translate(-200px,0px) scale(0.8) !important;
    }

    .tri {
        transform: translate(-95px,0px) scale(0.8) !important;
    }

    .box-enlighter {
        box-sizing: border-box;
        width: 114%;

        transform: translateY(-50px);
        margin-left: -24.5px;
        margin-top: 35px;
    }  

    .box-remover {
        background: transparent !important;
        box-shadow: none !important;
        height: 120px;
    }
/* 
    .first {
        border: solid 1px #565656;
        border-radius: 5px;
        display: flex;
        width: auto;
        height: auto;
        align-items: center;
        padding: 10px;
        margin-left: 0px !important;
    } */

    .internal-err {
        width: 15px;
        height: auto;
        background: red;
        padding: 20px 0 68px 0;
        margin: -10px;
    } 

    .save-spacer {
        transform: translateX(-15px) scale(0.65) !important;
    }

    .btn-lyster {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: -60px;
        transform: translateX(30px) scale(0.8) !important;
    }
    
    .bkw-btn {
        transform: translateX(-70px) !important;
    }

    .internal-btn {
        padding: .2rem;
        border-radius: .2rem;
        background: var(--primary); 
        color: white;
        float: right;
        transform:translate(1.4rem,3.5rem); 
        font-size: 13px
    }
}
 
@media (max-width: 450px) { 

    .btn-lyster {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: -60px;
        transform: translateX(30px) scale(0.5) !important;
    }

    .mbl-btn {
        transform: translate(-185px,0px) scale(0.5) !important;
    }

    .tri {
        transform: translate(-200px,0px) scale(0.5) !important;
    }

    .bkw-btn-non-summ {
        transform: translateX(-50px) !important;
    }

    .svd-btn-non-summ {
        transform: translateX(-230px) !important;
    }
    
    #step1Next {
        padding: 10px 25px 10px 50px;
        text-align: center;
        align-self: center;
        align-items: center;
        justify-content: center;
        transform: translateX(0px) !important;
    }

    .rqs-first {
        transform: translateX(200px);
        margin-left: -100px;
        margin-bottom: 20px;
    }

    .cpa-mover {
        margin-left: -440px;
    }

    .check-mover {
        margin-left: -440px;
    }

    .internal-btn {
        padding: .2rem;
        border-radius: .2rem;
        background: var(--primary); 
        color: white;
        float: right;
        transform:translate(1.4rem,3.5rem); 
        font-size: 13px
    }
}

/* 
@media (max-width: 574px) {
    #root {
        margin-left: 10px;
        width: 125%;
    }

    
    .rqs-first {
        margin-left: 150px;
        margin-bottom: 20px;
    }

    .mvr {
        margin-left: 15px !important;
    }

    .ass-mover {
        margin-left: 0px;
    }
    
    .ass-mover-save {
        margin-left: 110px;
    }

    .cpa-mover {
        margin-left: -260px;
    }

    .check-mover {
        margin-left: -260px;
    }

    .box-remover {
        background-color: white !important;
        height: 120px;
    }
    
    .box-enlighter {
        box-sizing: border-box;
        width: 20%;

        transform: translateY(50px);
        margin-left: -18px;
    }


    #step1Next {
        padding: 10px 25px 10px 50px;
        text-align: center;
        align-self: center;
        align-items: center;
        justify-content: center;
        transform: translateX(110px) !important;
    }

    .check-mover {
        margin-left: -260px;
    }

    .mbl-btn {
        transform: translate(-220px) scale(0.9) !important;
    }

    .tri {
        transform: translate(-100px) scale(0.9) !important;
    }

    .btn-lyster {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 350px;
        transform: translateX(25px);
    }
} */

/* 
@media (max-width: 574px) {
    #root {
        width: 103.5%;
        overflow-x: hidden;
    }


    .ttt {
        width: 100px;
        margin-left: 50px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
    } 
    
    
    .btn-spacer {
        display: flex;
        flex-direction: row;
    }

    .btn-lyster {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        transform: translateX(-90px);
        margin-left: 60px;
        transform: scale(0.7);
    }

    .save-spacer {
        transform: translateX(-115px) scale(0.5) !important;
    }


    #step1Next {
        padding: 10px 25px 10px 50px;
        text-align: center;
        align-self: center;
        align-items: center;
        justify-content: center;
        transform: translateX(110px) !important;
    }

    .check-mover {
        margin-left: -270px;
    }

    .mbl-btn {
        transform: translate(-230px,20px) scale(0.8) !important;
    }

    .tri {
        transform: translate(100px,-32px) scale(0.8) !important;
    }

    .box-enlighter {
        box-sizing: border-box;
        width: 115.6%;

        transform: translateY(-50px);
        margin-left: -24.5px;
        margin-top: 35px;
    }  

 
    .box-remover {
        background: transparent !important;
        box-shadow: none !important;
        height: 120px;
    }

    
    .mbl-rsz {
        transform: scale(.8);
        margin: 0 auto !important;
    }
} */






.spid-btn {
    width: 250px !important;
}


.flex-btn-centering {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -2rem;
}

.no-choice {
    opacity: 0;
    pointer-events: none;
}

.remove-btn {
    float: right;
}

/* < 1400 */
/* .flex-btn-centering {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: center;
    transform: translateX(410px);
    padding: 0 90px 0 175px !important;
} */
/* < 1200 */
/* .flex-btn-centering {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: center;
    transform: translateX(410px);
    padding: 0 105px 0 140px !important;
} */


/* < 1000 */
/* .flex-btn-centering {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: center;
    transform: translateX(410px);
    padding: 0 105px 0 140px !important;
} */