.service-expire-date {
  margin-bottom: 20px;
}
.service-expire-date .calendar-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
}
.service-expire-date .calendar-date h3 {
  margin-bottom: 0 !important;
}
.service-expire-date .calendar-date .calendar-date-day {
  width: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
}
.service-expire-date .calendar-date .calendar-date-day .line-container {
  position: absolute;
  right: -35px;
  width: 35px;
  top: 0;
  height: 100%;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-expire-date .calendar-date .calendar-date-day .line-container .line {
  height: 100%;
  width: 2px;
}
.service-expire-date .calendar-date .calendar-date-day .line-container .circle {
  width: 0.625rem;
  height: 0.625rem;
  position: absolute;
  background-color: inherit;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  border-radius: 5px;
}
.service-expire-date .calendar-date .calendar-date-description {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.17);
  height: 100%;
  flex-grow: 1;
  margin-left: 35px;
}
.service-expire-date .calendar-date .calendar-date-description .calendar-date-description-content {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.89rem;
}/*# sourceMappingURL=service.css.map */